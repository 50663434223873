
















































































































































































































import { Component, Prop, PropSync, Watch, Vue } from "vue-property-decorator";
import store from "@/store";
import _ from "underscore";
//  types
import { VesselDataWidgetColumn } from "@/types/VesselDataWidgetColumn";
import { Category as TaxonomyCategory } from "@/types/taxonomy/category";
import { LogVariable } from "@/types/logVariable";
import { ExtendedVessel } from "@/types/Vessel";
import { LogVariableTaxon } from "@/types/logVariableTaxon";
//  modules
import { getModule } from "vuex-module-decorators";
import TaxonomyModule from "@/store/clients/Taxonomy.module";
import LogVariablesModule from "@/store/clients/LogVariables.module";

const Taxonomy = getModule(TaxonomyModule, store);
const LogVariables = getModule(LogVariablesModule, store);

@Component({
  components: {},
})
export default class LogVariablesDialog extends Vue {
  @PropSync("value", { required: true }) isModalActive!: boolean;
  @PropSync("maxWidth", { default: 1000 }) dialogWidth!: number;
  @PropSync("column", { required: true }) syncColumn!: VesselDataWidgetColumn;
  @Prop({ required: true }) selectedVessel!: ExtendedVessel;
  @Prop({ type: Boolean, default: true }) validateLogVariablesByUnit!: boolean;
  @Prop({ type: Boolean, default: true }) displayTaxonomyTree!: boolean;

  selectedTaxonomyList: [] = [];
  filteredVesselLogVariables: [] | any = [];
  openCategories = [];
  searchTaxonomy: string | null = null;
  searchVariable: string | null = null;
  accumulatedAllowedUnits = ["rpm", "MT/hr", "MT/day", "knot", "kW", "kg/hr", "l/hr"];

  @Watch("isModalActive")
  onModalActive(currentState: boolean): void {
    if (!currentState) return;
    this.selectedTaxonomyList = [];
    this.setVesselFilteredVariables();
  }

  @Watch("searchVariable")
  onSearchVariableInput(searchVariable: string): void {
    if (!searchVariable) return;
    this.filteredVesselLogVariables = this.filteredVesselLogVariables.filter((variable: LogVariable) => variable.displayName.toLowerCase().includes(searchVariable.toLowerCase()));
  }

  get categorizedTaxonomyList(): TaxonomyCategory[] {
    return Taxonomy.categorizedTaxonomyList;
  }

  get selectedTaxonomyNames(): string[] {
    return _.pluck(this.selectedTaxonomyList, "fullName");
  }

  get isColumnFirstVariableSelected(): boolean {
    if (!this.syncColumn) return false;
    const columnFirstVariable = this.syncColumn.logVariables[0];
    if (!columnFirstVariable) return false;
    return this.selectedVessel.id === columnFirstVariable.vesselId;
  }

  get filteredByFirstColumnLogVariableUnit(): LogVariable[] {
    const vesselLogVariables: any = this.getVesselLogVariables(this.selectedVessel.id);
    const uniqVesselLogVariables: LogVariable[] = this.filterDuplicateLogVariables(vesselLogVariables);
    const columnFirstVesselLogVariable = this.syncColumn?.logVariables[0];
    if (columnFirstVesselLogVariable && !this.isColumnFirstVariableSelected) {
      return uniqVesselLogVariables.filter(variable => variable.unit.caption === columnFirstVesselLogVariable?.unit?.caption);
    } else {
      return uniqVesselLogVariables;
    }
  }

  close(): void {
    this.isModalActive = false;
    this.resetVariables();
  }

  isUnitAccAllowed(unit: string): boolean {
    return this.accumulatedAllowedUnits.includes(unit);
  }

  resetVariables(): void {
    this.filteredVesselLogVariables = [];
    this.selectedTaxonomyList = [];
    this.openCategories = [];
    this.searchTaxonomy = null;
    this.searchVariable = null;
  }

  setVesselSelectedVariable(variable: LogVariable): void {
    this.isModalActive = false;
    this.openCategories = [];
    this.searchVariable = null;
    const columnLogVariables = this.syncColumn.logVariables;
    const variableIndex = columnLogVariables.findIndex(item => item.vesselId === variable.vesselId);
    if (variableIndex !== -1) {
      Vue.set(columnLogVariables, variableIndex, variable);
    } else {
      this.syncColumn.logVariables.push(variable);
    }
  }

  setVesselFilteredVariables(): void {
    if (this.selectedTaxonomyNames.length && !this.isColumnFirstVariableSelected) {
      const firstVesselSelectedLogVariableUnit = this.syncColumn.logVariables[0]?.unit?.caption;
      this.filteredVesselLogVariables = this.filteredByTaxonomy(this.selectedVessel.id, firstVesselSelectedLogVariableUnit);
    } else if (this.selectedTaxonomyNames.length) {
      this.filteredVesselLogVariables = this.filteredByTaxonomy(this.selectedVessel.id);
    } else if (this.validateLogVariablesByUnit) {
      this.filteredVesselLogVariables = this.filteredByFirstColumnLogVariableUnit;
    } else {
      const vesselLogVariables: any = this.getVesselLogVariables(this.selectedVessel.id);
      this.filteredVesselLogVariables = this.filterDuplicateLogVariables(vesselLogVariables);
    }
  }

  getVesselLogVariables(vesselId: number): LogVariable[] | undefined {
    if (!vesselId) return;
    return LogVariables.vesselsLogVariables.find(vessel => vessel.vesselId === vesselId)?.logVariables;
  }

  filterDuplicateLogVariables(logVariables: LogVariable[]): LogVariable[] {
    return _.uniq(logVariables, variable => variable.displayName);
  }

  filteredByTaxonomy(vesselId: number, unitType: string | null = null): LogVariable[] {
    const filteredLogVariables: LogVariable[] = [];
    const vesselLogVariables: any = this.getVesselLogVariables(vesselId);

    vesselLogVariables.forEach((variable: LogVariable) => {
      if (!variable.taxa) return;
      variable.taxa.forEach((taxaItem: LogVariableTaxon) => {
        if (unitType && this.selectedTaxonomyNames.includes(taxaItem.name) && variable.unit.caption === unitType) {
          filteredLogVariables.push(variable);
        } else if (!unitType && this.selectedTaxonomyNames.includes(taxaItem.name)) {
          filteredLogVariables.push(variable);
        }
      });
    });
    return this.filterDuplicateLogVariables(filteredLogVariables);
  }
}
