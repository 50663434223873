


































































import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
//  types
import { VesselGroup } from "@/types/vesselGroup";
import { ExtendedVessel } from "@/types/Vessel";
//  modules
import VesselGroupsModule from "@/store/clients/VesselGroups.module";

const VesselGroups = getModule(VesselGroupsModule, store);

@Component({})
export default class VesselSelect extends Vue {
  @PropSync("selectedVesselsList", { type: Array, default: () => [] }) selectedVessels!: ExtendedVessel[];
  @Prop({ type: Array, default: () => [], required: true }) vesselsList!: ExtendedVessel[];
  @Prop({ type: Array, default: () => [] }) requiredFeatures!: string[];

  vesselGroup: VesselGroup | null = null;

  //returns vessels, and filters by vessel group if one is selected
  get filteredExtendedVessels(): ExtendedVessel[] {
    if (this.vesselGroup) {
      return this.vesselGroup.vesselIds.reduce((arr, id) => {
        arr.push(this.vesselsList.find(vessel => vessel.id === id));
        return arr;
      }, [] as any[]);
    }

    return this.vesselsList;
  }

  get vesselGroups(): VesselGroup[] {
    return VesselGroups.vesselGroups;
  }

  onVesselChange(vessel: ExtendedVessel): void {
    this.$emit("onVesselChange", vessel);
  }

  hasRequiredFeaturesEnabled(vessel: ExtendedVessel): boolean {
    const vesselEnabledFeaturesNameList = vessel.features.map(feature => feature.name);
    return this.requiredFeatures.every(feature => vesselEnabledFeaturesNameList.includes(feature));
  }

  requiredFeaturesMissing(vessel: ExtendedVessel): string[] {
    const vesselEnabledFeaturesNameList = vessel.features.map(feature => feature.name);
    return this.requiredFeatures.filter(feature => !vesselEnabledFeaturesNameList.includes(feature));
  }
}
